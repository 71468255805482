import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';

import Navbar from './components/Navbar';
import Welcome from './components/Welcome';
import DemoList from './components/DemoList';
import Demo from './components/Demo';
import EditDemo from './components/EditDemo';
import Admin from './components/Admin';

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container mt-4">
        <Route path="/" exact component={Welcome} />
        <Route path="/demo-list" exact component={DemoList} />
        <Route path="/demo/:slug" component={Demo} />
        <Route path="/edit-demo/:slug" component={EditDemo} />
        <Route path="/admin" component={Admin} />
      </div>
    </Router>
  );
}

export default App;
