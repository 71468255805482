import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

class DemoList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      demos: []
    };
  }

  componentDidMount() {
    axios.get('/api/demos')
      .then((response) => {
        this.setState({
          demos: response.data
        })
      })
  }

  render() {
    return (
      <div>
        <h2>Demo list</h2>
        <p>Click on a demo to edit it.</p>
        <div className="card-columns">
          {this.state.demos.map(demo =>
            <div className="card">
              <img className="card-img-top" src={demo.image} alt={demo.name} />
              <div className="card-body">
                <h5 className="card-title">{demo.name}</h5>
                <p className="card-text">{demo.description}</p>
                <p className="card-text"><small className="text-muted">Last updated {dayjs(demo.created_at).fromNow()}</small></p>
                <Link className="stretched-link" to={"/edit-demo/" + demo.slug} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

}

export default DemoList;