import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      demo: {},
      images: []
    };
  }

  render() {
    return (
      <div>
        <h2>Admin</h2>
        <p>Manage demos (Snappr staff only).</p>
        <Link className="btn btn-primary mr-3" to="/edit-demo/new">Create new demo</Link>
        <Link className="btn btn-primary mr-3" to="/demo-list">List exiting demos</Link>
      </div>
    )
  }

}

export default Admin;