import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo-dark.svg';
import arrows from '../images/button-arrows.svg';

class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} height="23" className="mr-2" alt="Snappr" />
            Editing Demo
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {/* <li className="nav-item">
                <Link className="nav-link" to="/">Upload</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/demo-list">List</Link>
              </li> */}
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="btn btn-snappr" href="https://app.snappr.co/book" target="_blank" rel="noopener noreferrer">Book now<img src={arrows} alt="Go" /></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }

}

export default Navbar;