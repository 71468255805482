import React, { Component } from 'react';
import ReactCompareImage from 'react-compare-image';
import axios from 'axios';

class Demo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      demo: {},
      images: []
    };
  }

  componentDidMount() {
    axios.get(`/api/demo/${this.props.match.params.slug}`)
      .then((response) => {
        this.setState({
          demo: response.data
        });
      });
    axios.get(`/api/demo/${this.props.match.params.slug}/images`)
      .then((response) => {
        this.setState({
          images: response.data
        });
      });
  }

  render() {
    return (
      <div>
        <img className="float-right" height="70" src={this.state.demo.image} alt={this.state.demo.name} />
        <h2>{this.state.demo.name}</h2>
        <p>{this.state.demo.description}</p>
        <div className="bg-white border rounded text-center">
          {this.state.images.map(image =>
          <div key={image.id}>
            <ReactCompareImage
              leftImage={image.before_image}
              rightImage={image.after_image}
              sliderLineColor="#ffffff"
              leftImageAlt="Before"
              rightImageAlt="After"
              rel="noopener noreferrer"
              leftImageLabel="Before"
              rightImageLabel="After"
              hover={true}
            />
            <p className="text-center font-italic">{image.caption}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

}

export default Demo;