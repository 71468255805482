import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Welcome extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slug: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    let clickedVariable = event.target.dataset.variable;
    let clickedValue = event.target.value;
    this.setState({
      [clickedVariable]: clickedValue
    })
  }

  render() {
    return (
      <div className="text-center mt-5">
        <h2>Welcome</h2>
        <p>Please enter the code given by your account manager</p>
        <input className="form-control w-50 mx-auto text-center" data-variable="slug" onChange={this.onChange} />
        <Link className="btn btn-primary mt-2" to={'/demo/'+this.state.slug}>Go to my demo</Link>
      </div>
    )
  }

}

export default Welcome;