import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlusSquare } from '@fortawesome/pro-light-svg-icons';

import placeholder from '../images/image-placeholder.png';

class EditDemo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      new: true,
      id: '',
      name: '',
      slug: '',
      description: '',
      image: '',
      caption: '',
      before_image: '',
      after_image: '',
      images: []
    };
    this.onChange = this.onChange.bind(this);
    this.onSaveDemo = this.onSaveDemo.bind(this);
    this.onSaveImage = this.onSaveImage.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.onUploadClick = this.onUploadClick.bind(this);
  }

  getImages() {
    axios.get(`/api/demo/${this.props.match.params.slug}/images`)
      .then((response) => {
        this.setState({
          images: response.data
        });
      });
  }

  componentDidMount() {
    if (this.props.match.params.slug !== "new") {
      axios.get(`/api/demo/${this.props.match.params.slug}`)
        .then((response) => {
          this.setState({
            new: false,
            id: response.data.id,
            name: response.data.name,
            slug: response.data.slug,
            description: response.data.description,
            image: response.data.image,
          });
        });
    }
    this.getImages();
  }

  onChange(event) {
    let clickedVariable = event.target.dataset.variable;
    let clickedValue = event.target.value;
    this.setState({
      [clickedVariable]: clickedValue
    })
  }

  onSaveDemo() {
    axios.post('/api/demo', {
      ...this.state.id && { id: this.state.id },
      name: this.state.name,
      slug: this.state.slug,
      description: this.state.description,
      image: this.state.image,
    })
      .then(response => {
        this.setState({
          new: false,
          id: response.data[0].id
        });
        this.props.history.push('/edit-demo/'+this.state.slug)
      });
  };

  onSaveImage() {
    axios.post('/api/image', {
      demo_id: this.state.id,
      caption: this.state.caption,
      before_image: this.state.before_image,
      after_image: this.state.after_image
    })
      .then(response => {
        this.getImages();
        this.setState({
          caption: '',
          before_image: '',
          after_image: ''
        });
      })
  }

  onDeleteImage(event) {
    axios.delete('/api/image/' + event.target.dataset.id, {})
      .then(response => {
        this.getImages();
      })
  }

  onUploadClick(event) {
    event.preventDefault();
    let imageType = event.target.dataset.type;
    var uploadWidget = window.cloudinary.createUploadWidget({
      // cloudName: "snappr",
      cloud_name: "snappr",
      // uploadPreset: "hxuzxfmv",
      upload_preset: "hxuzxfmv",
      sources: [
        "url",
        "local"
      ],
      showAdvancedOptions: false,
      cropping: true,
      multiple: false,
      defaultSource: "local",
      styles: {
        palette: {
          window: "#ffffff",
          sourceBg: "#F8F9FA",
          windowBorder: "#90a0b3",
          tabIcon: "#000000",
          inactiveTabIcon: "#555a5f",
          menuIcons: "#555a5f",
          link: "#007BFF",
          action: "#28A745",
          inProgress: "#007BFF",
          complete: "#28A745",
          error: "#DC3545",
          textDark: "#000000",
          textLight: "#fcfffd"
        },
        fonts: {
          default: null,
          "sans-serif": {
            url: null,
            active: true
          }
        }
      }
    }, (error, result) => {
      console.log(result);
      if (result && result.length > 0) {
        uploadWidget.close();
        let imageUrl = result[0].url;
        this.setState({ [imageType]: imageUrl });
      }
    })
    uploadWidget.open();
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <Link className="float-right" to={"/demo/" + this.state.slug}>Open demo</Link>
            <h5 className="card-title">Edit demo</h5>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Demo name</label>
                  <input className="form-control" placeholder="Name of the demo" data-variable="name" value={this.state.name} onChange={this.onChange} />
                </div>
                <div className="form-group">
                  <label>Slug</label>
                  <input className="form-control" placeholder="Slug for demo URL" data-variable="slug" value={this.state.slug} onChange={this.onChange} />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Description</label>
                  <input className="form-control" placeholder="Description of the demo" data-variable="description" value={this.state.description} onChange={this.onChange} />
                </div>
                <div className="form-group">
                  <label>Image</label>
                  <input className="form-control" placeholder="Image to be displayed in demo" data-variable="image" value={this.state.image} onChange={this.onChange} />
                </div>
              </div>
            </div>
            <button className="btn btn-snappr" onClick={this.onSaveDemo}>Save</button>
          </div>
        </div>
        {!this.state.new &&
          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title">Edit demo images</h5>


              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Before image</th>
                    <th scope="col">After image</th>
                    <th scope="col">Caption</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.images.map(image =>
                    <tr key={image.id}>
                      <th><img height="50" src={image.before_image} data-type="before_image" alt="Before" /></th>
                      <td><img height="50" src={image.after_image} data-type="after_image" alt="After" /></td>
                      <td>{image.caption}</td>
                      <td className="text-danger"><FontAwesomeIcon icon={faTrashAlt} size="2x" data-id={image.id} onClick={this.onDeleteImage} /></td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      {this.state.before_image ?
                        <img src={this.state.before_image} height="50" alt="Before" />
                        :
                        <button className="btn btn-link px-0" data-type="before_image" onClick={this.onUploadClick}>Upload new before image</button>
                      }
                    </td>
                    <td>
                      {this.state.after_image ?
                        <img src={this.state.after_image} height="50" alt="After" />
                        :
                        <button className="btn btn-link px-0" data-type="after_image" onClick={this.onUploadClick}>Upload new after image</button>
                      }
                    </td>
                    <td>
                      <input className="form-control" placeholder="New caption" data-variable="caption" value={this.state.caption} onChange={this.onChange} />
                    </td>
                    <td className="text-success"><FontAwesomeIcon icon={faPlusSquare} size="2x" onClick={this.onSaveImage} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    )
  }

}

export default EditDemo;