import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/TiemposHeadlineWeb-Bold.woff';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister(); // Change unregister() to register() for app to work offline
